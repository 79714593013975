import _ from 'lodash'
import { Entity } from '../../../models/entity';
import { ApplicationContext } from '../applicationContext';

// Any valid javascript identifier, including dot expressions.
const IDENTIFIER_REGEX = /{([A-Za-z0-9_.]+)}/g

export function translateStringInFormula(
    translationTable: any,
    source: string,
    context: any
): string {
    const appContext = this as ApplicationContext
    // TODO(Bobby D): Where to get the current document or storyboard entity?
    return translateString(source, translationTable, appContext?.getLocale() ?? 'en_US', context)
}

export function translateString(
    source: string,
    translations: any,
    destinationLocale: string = "en_US",
    context?: any
): string {
    // Normalize destinationLocale
    destinationLocale = destinationLocale.replace('-', '_')

    // Get the translation entry from the source Entity's translations
    const translation = translationTable(source, translations, destinationLocale)
    if (_.isEmpty(context) && _.isEmpty(translation)) return source

    // if it's a simple string/string translation we can just return the translated string
    if (_.isEmpty(context) && !_.isObject(translation)) return translation

    // Get a substitutable string, resolving plurals if appropriate
    const translateString = getParameterizedString(source, translation, context)

    return hydrate(translateString, context)
}

function translationTable(source: string, translationTable: any, destinationLocale: string): any {
    // Find the source string in the entity's translation table
    if (_.isEmpty(translationTable)) return null

    // find the destination locale. Note there may be e.g. "en", "en_US". A search for "en_UK" should return
    // the entry for "en", while a search for "en_US" should return the more specific one.
    const destinationLanguage = destinationLocale.substring(0,destinationLocale.indexOf('_'))
    return translationTable[destinationLocale]?.[source]
        || translationTable[destinationLanguage]?.[source]
}

function getParameterizedString(source: string, translation: any, context?: any): string {
    if (!_.isObject(translation)) {
        return translation || source
    }
    // Find the index variable. If it's not explicitly specified,
    // assume the first name in braces.
    const index = translation['index'] || IDENTIFIER_REGEX.exec(source)?.[1]
    if (_.isNil(index)) {
        return source
    }

    // Match exact count, then "many" (int > 1 and not exactly matched) then "other" (negative, float, etc)
    const count = parseFloat(context[index])
    return translation[count]
        || (_.isInteger(count) && count > 1 && translation["many"])
        || translation["other"]
        || source
}

export function hydrate(source: string, context?: any): string {
    const matches = source.match(IDENTIFIER_REGEX)
    return _.reduce(matches, (result, match) => {
        const name = match.substring(1, match.length - 1)
        // If we don't have a value, substitute the name, so we can easily see if we missed a substitution
        const value = context?.[name] || name
        return result.replace(match, value)
    }, source)
}
